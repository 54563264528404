import i1 from './1.jpg'
import i2 from './2.jpg'
import i3 from './3.jpg'


const data = [
    { src: i2 },
    { src: i3 },
    { src: i1 },

]
export default data