import i1 from './1.jpg'
import i2 from './2.jpg'
import i3 from './3.jpg'
import i4 from './4.jpg'
import i5 from './5.jpg'
import i6 from './6.jpg'
import i7 from './7.jpg'
import i8 from './8.jpg'
import i9 from './9.jpg'
import i10 from './10.jpg'
import i11 from './11.jpg'
import i12 from './12.jpg'
import i13 from './13.jpg'
import i14 from './14.jpg'

const data = [
    { src: i1 },
    { src: i2 },
    { src: i3 },
    { src: i4 },
    { src: i5 },
    { src: i6 },
    { src: i7 },
    { src: i8 },
    { src: i9 },
    { src: i10 },
    { src: i11 },
    { src: i12 },
    { src: i13 },
    { src: i14 },
]
export default data